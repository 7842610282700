.home-page-container {
  opacity: 1;
  height: auto;
  min-height: 50vh;
  color: white;
  margin: 0 auto;
  z-index: 0;
  font-size: medium;
  top: 10vh;
  position: relative;
  width: 85vw;
  max-height: 80vh;
  display: grid;
  gap: 2vw;
  grid-template-columns: 30vw 1fr;
}
.home-page-container .title-container {
  width: 45vw;
  height: 120px;
  margin-top: 17vh;
  background: rgba(0, 0, 0, 0.195);
  margin-left: 6vw;
}
.home-page-container .title-container img {
  display: block;
  max-width: 45vw;
}
.home-page-container .title-container p {
  text-align: center;
  color: #ffebcd;
}
.home-page-container .video-container video {
  margin-top: 7vh;
  width: 30vw;
  box-shadow: 0 0 0 3px #fff;
}
@media (max-width: 900px) {
  .home-page-container {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
  }
  .home-page-container .title-container {
    grid-row: 1;
    margin: 0 auto;
    padding-left: 0;
    height: 90px;
    width: 100%;
  }
  .home-page-container .title-container img {
    margin: 0 auto;
  }
  .home-page-container .video-container {
    grid-row: 2;
  }
  .home-page-container .video-container video {
    width: 100%;
  }
}
