@media (min-width: 821px) {
  #Kontakt .content-display {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  #Kontakt .content-display .contact-form {
    grid-column-start: 1;
    grid-column-end: 2;
    margin-left: 90px;
  }
  #Kontakt .content-display .contact-form .title-text {
    font-size: 18px;
  }
  #Kontakt .content-display .contact-form .inner-contact-form {
    display: grid;
    grid-template-rows: 15% 15% auto 10%;
    width: 275px;
    grid-gap: 10px;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .name-input,
  #Kontakt .content-display .contact-form .inner-contact-form .email-input {
    margin-top: 3px;
    width: 275px;
    border: none;
    background-color: #171717;
    height: 25px;
    color: whitesmoke;
    border-radius: 5px;
    cursor: pointer;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .inputarea {
    margin-top: 3px;
    width: 275px;
    border: none;
    background-color: #171717;
    height: 270px;
    color: whitesmoke;
    border-radius: 5px;
    cursor: pointer;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit {
    position: relative;
    cursor: pointer;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn {
    position: absolute;
    width: 275px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #4c4c4c;
    text-decoration: none;
    font-size: 2em;
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5em 1.5em;
    border: 2px solid #4c4c4c;
    transition: 0.02s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #4c4c4c;
    transition: 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s cubic-bezier(0.1, 0, 0.1, 1);
    z-index: -1;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn::after {
    content: '';
    display: inline-block;
    background-image: url('https://image.flaticon.com/icons/png/128/109/109617.png');
    position: absolute;
    top: 0;
    left: calc(100% - 3em);
    right: 3em;
    bottom: 0;
    background-size: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    transition: right 0.3s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn:hover {
    padding: 0.5em 3.5em 0.5em 1.5em;
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn:hover::before {
    left: calc(100% - 3em);
    right: 0;
    transition: 0.3s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .content-display .contact-form .inner-contact-form .submit .btn:hover::after {
    right: 0;
    transition: right 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .content-display .contact-form .error {
    color: red;
    padding-bottom: 50px;
  }
  #Kontakt .content-display .contact-form .success {
    margin-top: 15px;
    text-align: center;
  }
  #Kontakt .content-value {
    grid-column-start: 2;
    grid-column-end: 3;
    opacity: 0.8;
    margin-top: 42vh;
    margin-left: 6vw;
  }
}
@media (max-width: 820px) {
  #Kontakt .visible {
    padding: 40px 0;
  }
  #Kontakt .visible .content-display {
    display: grid;
    grid-template-rows: 1fr 1fr;
    text-align: center;
  }
  #Kontakt .visible .content-display .contact-form {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  #Kontakt .visible .content-display .contact-form .title-text {
    font-size: 18px;
    text-align: center;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form {
    display: grid;
    grid-template-rows: 15% 15% auto 10%;
    grid-gap: 10px;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .name-input,
  #Kontakt .visible .content-display .contact-form .inner-contact-form .email-input {
    margin-top: 3px;
    width: 275px;
    border: none;
    background-color: #171717;
    height: 25px;
    color: whitesmoke;
    border-radius: 5px;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .inputarea {
    margin-top: 3px;
    width: 275px;
    border: none;
    background-color: #171717;
    height: 275px;
    color: whitesmoke;
    border-radius: 5px;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit {
    position: relative;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #4c4c4c;
    text-decoration: none;
    font-size: 2em;
    display: inline-block;
    text-transform: uppercase;
    padding: 0.5em 1.5em;
    border: 2px solid #4c4c4c;
    transition: 0.02s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    background: #4c4c4c;
    transition: 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s cubic-bezier(0.1, 0, 0.1, 1);
    z-index: -1;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn::after {
    content: '';
    display: inline-block;
    background-image: url('https://image.flaticon.com/icons/png/128/109/109617.png');
    position: absolute;
    top: 0;
    left: calc(100% - 3em);
    right: 3em;
    bottom: 0;
    background-size: 1.5em;
    background-repeat: no-repeat;
    background-position: center;
    transition: right 0.3s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn:hover {
    padding: 0.5em 1.5em 0.5em 1.5em;
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn:hover::before {
    left: calc(100% - 3em);
    right: 0;
    transition: 0.3s cubic-bezier(0.1, 0, 0.1, 1), left 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .visible .content-display .contact-form .inner-contact-form .submit .btn:hover::after {
    right: 0;
    transition: right 0.3s 0.2s cubic-bezier(0.1, 0, 0.1, 1);
  }
  #Kontakt .visible .content-display .contact-form .error {
    color: red;
  }
  #Kontakt .visible .content-display .contact-form .success {
    margin-top: 15px;
    text-align: center;
  }
  #Kontakt .visible .content-value {
    grid-row-start: 1;
    grid-row-end: 2;
    font-size: 15px;
    margin-top: 150px;
  }
}
