.description {
  padding-bottom: 40px;
}
.slider-container {
  overflow-x: hidden;
  overflow-y: hidden;
}
.slider-container .ripple-container {
  margin-top: 125px;
  height: 200px;
}
.slider-container .ripple-container .ripple {
  text-decoration: none;
  color: #fff;
  width: 12px;
  height: 12px;
  background-color: #ff0000;
  margin: 0 auto;
  border-radius: 100px;
  -webkit-animation: ripple 0.7s linear infinite;
  animation: ripple 0.7s linear infinite;
}
@-webkit-keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3), 0 0 0 10px rgba(255, 0, 0, 0.3), 0 0 0 30px rgba(255, 0, 0, 0.3), 0 0 0 60px rgba(255, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.3), 0 0 0 30px rgba(255, 0, 0, 0.3), 0 0 0 60px rgba(255, 0, 0, 0.3), 0 0 0 90px rgba(255, 0, 0, 0);
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.3), 0 0 0 10px rgba(255, 0, 0, 0.3), 0 0 0 30px rgba(255, 0, 0, 0.3), 0 0 0 60px rgba(255, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 0, 0, 0.3), 0 0 0 30px rgba(255, 0, 0, 0.3), 0 0 0 60px rgba(255, 0, 0, 0.3), 0 0 0 90px rgba(255, 0, 0, 0);
  }
}
.slider-container .slider {
  transition: 0.2s;
  z-index: 0;
}
.slider-container .slider .gallery-item-container {
  float: left;
}
.slider-container .slider .gallery-item-container .gallery-items {
  border-style: none;
  padding: 0 10px;
  cursor: pointer;
}
.arrow-container {
  position: relative;
}
.arrow-container #right-arrow {
  right: -30px;
}
.arrow-container #right-arrow .bi {
  margin-left: -15px;
}
.arrow-container #left-arrow {
  left: -30px;
}
.arrow-container #left-arrow .bi {
  margin-left: -15px;
}
.arrow-container .slider-arrow {
  position: absolute;
  color: whitesmoke;
  width: 38px;
  cursor: pointer;
}
.arrow-container .slider-arrow.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.arrow-container .slider-arrow:hover {
  background-color: rgba(245, 245, 245, 0.445);
  transition: 0.15s;
}
@media (max-width: 737px) {
  .slider-container {
    margin: 0 -2.5vw;
  }
  .arrow-container {
    position: relative;
  }
  .arrow-container #right-arrow .bi {
    margin-left: 0px;
  }
  .arrow-container #left-arrow .bi {
    margin-left: -30px;
  }
}
