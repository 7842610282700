header {
  background-color: black;
  height: 45px;
  line-height: 45px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 5;
}
header .menu {
  height: 100%;
}
header .menu a {
  color: blanchedalmond;
  text-transform: uppercase;
  height: 100%;
  display: inline-block;
  transition: 100ms;
  font-size: calc(17px + 9 * ((100vw - 1024px) / 1536));
  padding: 0 0.9vmax;
}
header .menu a:hover {
  color: red;
}
header .menu a.active {
  color: red;
}
@media (max-width: 971px) {
  .mobile-menu {
    height: 45px;
    cursor: pointer;
    color: white;
  }
  .mobile-menu .menu-icon {
    color: white;
  }
  .menu-container {
    overflow: scroll;
    height: 90vh;
    width: 103vw;
  }
  .menu-container .menu {
    color: white;
    display: grid;
    background-color: #000000f0;
    height: auto !important;
    text-align: center;
  }
  .menu-container .menu a {
    font-size: 20px;
  }
}
