#root,
.App,
main,
.page {
  height: 100%;
}
.App {
  padding-top: 45px;
}
.page {
  background-repeat: no-repeat;
  background-position: center center;
  background-position-y: top;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.page .content-container {
  height: auto;
  min-height: 50vh;
  background: rgba(0, 0, 0, 0.185);
  color: blanchedalmond;
  margin: 0 auto;
  backdrop-filter: blur(1.5px);
  padding: 40px;
  z-index: 0;
  font-size: 18px;
  top: 10vh;
  position: relative;
  max-width: 1069px;
  width: 90vw;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.page .content-container .title {
  text-transform: uppercase;
}
.page .content-container .content-value.html p {
  margin-bottom: 0;
}
.page .content-container .content-value.html p:empty {
  margin-bottom: 5px;
}
.page .content-container .content-value.html a {
  color: red;
}
.page.content-left .content-container {
  margin: 0 0 0 5vw !important;
}
.page.thin .content-container {
  max-width: 600px;
}
.page.spacing-top .content-container {
  margin-left: 3vw !important;
  top: 40vh;
  height: 275px;
  padding-top: 0;
}
.page.spacing-top .content-container .description {
  padding-bottom: 25px;
}
